import cx from 'classnames'
import clsx from 'clsx'
import { memo } from 'react'

type Props = {
  label: string | React.ReactNode
  children?: React.ReactNode
  value?: React.ReactNode
  isTop?: boolean
  isWarning?: boolean
  className?: string
}

export default memo(function ExchangeInfoRow(props: Props) {
  const { label, children, value, isTop, isWarning, className } = props

  return (
    <div
      className={cx('flex justify-between', className, {
        'top-line': isTop,
      })}
    >
      <div
        className={clsx(
          'text-sm font-normal text-th-fgd-3',
          String(label).toLowerCase().includes('liquidation') &&
            '!text-th-fgd-1',
        )}
      >
        {label}
      </div>
      <div
        className={`align-right flex items-center text-sm font-medium ${
          isWarning ? 'text-th-warning' : 'text-th-fgd-1'
        }`}
      >
        {children || value}
      </div>
    </div>
  )
})
