import clsx from 'clsx'
import NumberInput from 'components/NumberInput/NumberInput'
import { INPUT_LABEL_SEPARATOR, PERCENTAGE_SUGGESTIONS } from 'config/ui'
import { ChangeEvent, memo, ReactNode, useRef, useState } from 'react'

type Props = {
  topLeftLabel: string
  topLeftValue?: string
  topRightLabel?: string
  topRightValue?: string
  onClickTopRightLabel?: () => void
  inputValue?: number | string
  onInputValueChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onClickMax?: () => void
  onFocus?: () => void
  onBlur?: () => void
  showMaxButton?: boolean
  staticInput?: boolean
  children?: ReactNode
  showPercentSelector?: boolean
  onPercentChange?: (percentage: number) => void
  containerClass?: string
}

function BuyInputSection(props: Props) {
  const {
    topLeftLabel,
    topLeftValue,
    topRightLabel,
    topRightValue,
    // onClickTopRightLabel,
    inputValue,
    onInputValueChange,
    onClickMax,
    onFocus,
    onBlur,
    showMaxButton,
    staticInput,
    children,
    showPercentSelector,
    onPercentChange,
    // containerClass,
  } = props
  const [isPercentSelectorVisible, setIsPercentSelectorVisible] =
    useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  function handleOnFocus() {
    if (showPercentSelector && onPercentChange) {
      setIsPercentSelectorVisible(true)
    }
    onFocus?.()
  }

  function handleOnBlur() {
    if (showPercentSelector && onPercentChange) {
      setIsPercentSelectorVisible(false)
    }
    onBlur?.()
  }

  function handleBoxClick() {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  function onUserInput(e: ChangeEvent<HTMLInputElement>) {
    if (onInputValueChange) {
      onInputValueChange(e)
    }
  }

  function balanceClickHandler() {
    if (showMaxButton && onClickMax) {
      onClickMax()
    }
  }

  return (
    <div className="w-full">
      <div onClick={handleBoxClick}>
        <div className="mb-2 mt-3 flex items-center justify-between">
          <p className="font-flexo text-xs font-medium text-th-fgd-3">
            {topLeftLabel}
            {topLeftValue && `${INPUT_LABEL_SEPARATOR} ${topLeftValue}`}
          </p>
          <span className="Exchange-swap-label">
            <span className="font-flexo text-xs font-medium text-th-fgd-3">
              {topRightLabel || ''}
            </span>
            {topRightLabel ? INPUT_LABEL_SEPARATOR : ''}&nbsp;
            <span
              className={clsx(
                'text-th-fgd-1',
                showMaxButton && 'cursor-pointer',
              )}
              onClick={balanceClickHandler}
            >
              {topRightValue || ''}
            </span>
            {showMaxButton && topRightValue && (
              <button
                type="button"
                className="ml-1 text-th-fgd-3"
                onClick={onClickMax}
              >
                MAX
              </button>
            )}
          </span>
        </div>
        {/* <div>
          <div className="text-th-fgd-3">
            {topLeftLabel}
            {topLeftValue && `${INPUT_LABEL_SEPARATOR} ${topLeftValue}`}
          </div>
          <div className={clsx("align-right", { clickable: onClickTopRightLabel })} onClick={onClickTopRightLabel}>
            <span className="text-th-fgd-3">{topRightLabel}</span>
            {topRightValue && (
              <span className="Exchange-swap-label">
                {topRightLabel ? INPUT_LABEL_SEPARATOR : ""}&nbsp;{topRightValue}
              </span>
            )}
          </div>
        </div> */}
        <div className="flex w-full items-center justify-between rounded-md border border-th-input-border-secondary bg-th-bkg-2 p-2 pl-2 font-flexo text-sm font-bold text-th-fgd-1 placeholder:!text-th-fgd-1 focus:border-th-fgd-3 focus:outline-none md:hover:!border-th-fgd-1 md:hover:focus-visible:!border-th-fgd-1 lg:text-base">
          {!staticInput && (
            <NumberInput
              value={inputValue}
              className="flex-1 bg-th-bkg-2 focus:outline-none"
              inputRef={inputRef}
              onValueChange={onUserInput}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              placeholder="0.0"
            />
          )}
          {staticInput && (
            <div className="w-full flex-1 text-lg text-th-fgd-1">
              {inputValue}
            </div>
          )}
          <div className="flex h-full flex-shrink-0 items-center justify-center whitespace-nowrap text-sm font-medium text-th-fgd-1">
            {children}
          </div>
        </div>
      </div>
      {showPercentSelector && isPercentSelectorVisible && onPercentChange && (
        <ul
          className="absolute mt-0 flex list-none rounded-sm p-0 backdrop-blur"
          style={{
            background:
              'linear-gradient(270deg, rgb(24, 24, 24) 0%, rgb(24, 24, 24) 100%)',
            boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.25)',
          }}
        >
          {PERCENTAGE_SUGGESTIONS.map((percentage) => (
            <li
              className="w-[72px] cursor-pointer rounded-sm px-3 py-2 text-center text-xs hover:bg-[#b4bbff1a]"
              key={percentage}
              onMouseDown={() => {
                onPercentChange?.(percentage)
                handleOnBlur()
              }}
            >
              {percentage}%
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default memo(BuyInputSection)
